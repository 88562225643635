<template>
  <div>
    <Sidenav
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :item-edit.sync="itemEdit"
      @refetch-data="refetchData"
      :center-options="centerOptions"
      :sector-options="sectorOptions"
      :unit-options="unitOptions"
    />
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />
    <!-- Filters -->
    <filters
      :center-filter.sync="centerFilter"
      :center-options="centerOptions"
      :sector-filter.sync="sectorFilter"
      :sector-options="sectorOptions"
      :tenant-filter.sync="tenantFilter"
      :tenant-options="tenantOptions"
      :bridge-filter.sync="bridgeFilter"
      :bridge-options="bridgeOptions"
      :online-filter.sync="onlineFilter"
      :rtsp-available-filter.sync="rtspAvailableFilter"
      @update-camera-data="updateCameras"
    />

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize ml-1"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon icon="DownloadCloudIcon" size="15"/>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(bridge)="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(tenant)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(unit)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(center)="data">
          <span> {{ $tc(data.label) }}</span>
        </template>
        <template #head(sector)="data">
          <span> {{ $tc(data.label) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- Column: Preview -->
        <template #cell(name)="data">
          <div style="position: relative;
                        width: 250px;">
            <img :src="`${snapshotUrl}${data.item.camera.id}?access_token=${token}`"
              style="width: 250px; border-radius: 16px;"/>
            <div style="z-index: 1;
              position: absolute;
              width: -webkit-fill-available;
              color: white;
              background: rgba(0,0,0,.5);
              padding: 5px;
              top: 0;
              right: 0;
              left: 0;
              border-top-left-radius: 16px;
              border-top-right-radius: 16px;
            ">
              <b-media vertical-align="center">
                <template #aside>
                  <div :style="`background: ${data.item.camera.online ? 'green': 'red'};
                            width: 1.5rem;
                            height: 1.5rem;
                            border-radius: 1rem;
                            text-align: center;`">
                  </div>
                </template>
                <small style="font-weight: bolder; font-size: 14px;">{{ data.item.name }}</small>
              </b-media>
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="updateStatusCamera(data.item.camera.id)">
              <feather-icon icon="RefreshCwIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ $t("actions.update") }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item @click="openEdit(data.item)"
                             v-if="$can('update', 'CameraBridge')">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.edit")
                }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDelete(data.item.id)"
                             v-if="$can('delete', 'CameraBridge')">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">{{ $t("actions.delete") }}</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.camera.rtsp_available && $can('refresh_flow', 'CameraProxy')" @click="refreshRtpsFlow(data.item)">
              <feather-icon icon="CastIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ `${$t("actions.refresh")} RTSP` }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref, computed} from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Filters from "./Filters.vue";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import axios from 'axios';

export default {
  components: {
    Sidenav,
    Filters,
    Breadcrumb,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    Pagination
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const exportExcel = () => {
      action.value = "export";
      fetchList();
    };
    // start breadcrumb and export
    const buttonExport = ref(false);
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.ee_vms.cameras");
    const notifications = computed(() => store.getters["grid/getAlarms"]);
    // end breadcrumb

    const snapshotUrl = `${process.env.VUE_APP_BASE_URL}/v1/services/snapshot/`

    const isEditSidebarActive = ref(false);
    const itemEdit = ref({});
    const openEdit = (item) => {
      isEditSidebarActive.value = true;
      itemEdit.value = item;
    };
    const {t} = useI18nUtils();
    const toast = useToast();
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      action,
      centerOptions,
      centerFilter,
      sectorOptions,
      sectorFilter,
      tenantOptions,
      tenantFilter,
      bridgeOptions,
      bridgeFilter,
      unitOptions,
      onlineFilter,
      rtspAvailableFilter,
    } = useList();

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: context.parent.$i18n.t("messages.delete.title"),
        text: context.parent.$i18n.t("messages.delete.body"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t("actions.delete"),
        cancelButtonText: context.parent.$i18n.t("actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("bd_camera/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    const updateCameras = () => {
      store
        .dispatch("bd_camera/update", {
          id_bridges: bridgeFilter.value,
        })
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            refetchData();
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const refreshRtpsFlow = (camera) => {
      axios.get(`${process.env.VUE_APP_MS_RTSP_URL}refresh`,
        {
          params: { id: camera.camera.id },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'X-Host': process.env.VUE_APP_BASE_URL
          },
      })
        .then(result => {
          toast({
              component: ToastificationContent,
              props: {
                title: 'Actualizadó correctamente',
                icon: "CheckIcon",
                variant: "success",
              },
            });
        });
    }

    const updateStatusCamera = (id) => {
      store
        .dispatch("camera_proxy/updateStatus", id)
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          refetchData();
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    return {
      buttonExport,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      notifications,
      isEditSidebarActive,
      itemEdit,
      openEdit,
      confirmDelete,
      updateCameras,
      exportExcel,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      centerOptions,
      centerFilter,
      sectorOptions,
      sectorFilter,
      tenantOptions,
      tenantFilter,
      bridgeOptions,
      bridgeFilter,
      unitOptions,
      update,
      refreshRtpsFlow,
      onlineFilter,
      rtspAvailableFilter,

      snapshotUrl,
      token: localStorage.accessToken,
      updateStatusCamera
    };
  },
};
</script>