import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import router from "@/router";
import { debounce } from "lodash";

export default function useList() {
  const refListTable = ref(null);
  const toast = useToast();
  store.dispatch("center/getAll");
  store.dispatch("sector/getAll");
  store.dispatch("tenant/getAll");
  store.dispatch("unit/getAll", { with: "router:id,id_tenant" });
  store.dispatch("bd_bridge/getAll");
  const centerOptions = computed(() => store.getters["center/getSelectedItems"]);
  const centerFilter = ref(null);
  const tenantOptions = computed(() => store.getters["tenant/getSelectedItems"]);
  const tenantFilter = ref(null);
  const bridgeOptions = computed(() => store.getters["bd_bridge/getSelectedItems"]);
  const bridgeFilter = ref(null);
  const sectorOptions = computed(() => store.getters["sector/getSelectedItems"]);
  const sectorFilter = ref(null);
  const unitOptions = computed(() => store.getters["unit/getSelectedItems"]);

  const onlineFilter = ref(null)
  const rtspAvailableFilter = ref(null)

  if (router.currentRoute.params.id_bridge)
    bridgeFilter.value = [parseInt(router.currentRoute.params.id_bridge)];

  const tableColumns = [
    { label: "actions.name", key: "actions" },
    { label: "camera.name", key: "name", sortable: true },
    { label: "bridge.title", key: "bridge.device_name", sortable: true },
    {
      label: "unit.title",
      key: "units",
      sortable: false,
      formatter: (value, key, item) => {
        if (value)
          return value
            .map((item) => {
              return item.description;
            })
            .join(", ");
        return "";
      },
    },
    {
      label: "tenants.title",
      key: "tenants",
      sortable: false,
      formatter: (value, key, item) => {
        if (item.units)
          return [
            ...new Set(
              item.units.map((item) => {
                return item.router?.tenant?.name;
              })
            ),
          ].join(", ");
        return "";
      },
    },
    { label: "centers.title", key: "center.name", sortable: true },
    { label: "sectors.title", key: "sector.name", sortable: true },
    { label: 'camera_proxy.last_connection', key: 'camera.last_connection', sortable: true },
    { label: 'camera_proxy.id', key: 'camera.id', sortable: true },
    {
      label: "camera.previous_time_of_prealarma",
      key: "delayed_record_minutes",
      sortable: true,
    },
    {
      label: "camera.later_time_of_prealarma",
      key: "forward_record_minutes",
      sortable: true,
    },
    { label: "camera.address", key: "address", sortable: true },
    { label: "camera.esn", key: "esn", sortable: true },
    { label: 'camera_proxy.rtsp_available', key: 'camera.rtsp_available', sortable: true },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const action = ref("list");
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });
  const fetchList = (ctx, callback) => {
    store
      .dispatch("bd_camera/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_bridge: bridgeFilter.value,
        id_center: centerFilter.value,
        id_sector: sectorFilter.value,
        id_tenant: tenantFilter.value,
        action: action.value,
        online: onlineFilter.value,
        rtsp_available: rtspAvailableFilter.value,
      })
      .then((response) => {
        if (action.value == "export") {
          action.value = "list";
        } else {
          callback(response.data);
          totalList.value = response.meta.total;
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };
  const refetchData = () => {
    refListTable.value.refresh();
  };
  watch(
    [
      centerFilter,
      sectorFilter,
      tenantFilter,
      bridgeFilter,
      onlineFilter,
      rtspAvailableFilter,
      currentPage,
      perPage,
    ],
    () => {
      refetchData();
    }
  );

  watch([ searchQuery ], debounce(() => {
    refetchData();
  }, 500));

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    action,
    centerOptions,
    centerFilter,
    sectorOptions,
    sectorFilter,
    tenantOptions,
    tenantFilter,
    bridgeOptions,
    bridgeFilter,
    unitOptions,
    onlineFilter,
    rtspAvailableFilter,
    refetchData,
  };
}
